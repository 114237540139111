import React, { useCallback, useContext, useEffect, useRef } from 'react'
import cx from 'classnames'
import TrapFocus from 'trap-focus'
import { useIntl } from 'intl'
import { useDevice } from 'device'

import { Icon, type IconName } from 'components/dataDisplay'
import { buttonMessages } from 'components/inputs'
import { PlainModalBgColorToClassName, type PlainModalProps } from 'components/feedback'

import { ModalWithLayersContext } from '../../util'

import s from './Layer.module.css'


type LayerProps = {
  id: string
  bgColor?: PlainModalProps['bgColor']
  backButtonStyle?: 'black-icon' | 'action-button'
  pxMobile?: 16 | 40
  scrollIntoView?: boolean
}

const ANIMATION_DURATION = 300

const Layer: React.CFC<LayerProps> = (props) => {
  const { children, id, bgColor = 'gray-10', backButtonStyle = 'black-icon', pxMobile = 16, scrollIntoView = false } = props

  const { activeLayers, closeLayer } = useContext(ModalWithLayersContext)

  const { isMobile } = useDevice()

  const intl = useIntl()
  const layerRef = useRef<HTMLDivElement>(null)
  const timeoutRef = useRef(null)

  const isVisible = activeLayers.has(id)

  const backButtonLabel = intl.formatMessage(buttonMessages.back)

  const handleCloseLayer = useCallback(() => {
    layerRef.current.classList.add(s.close)

    timeoutRef.current = setTimeout(() => {
      closeLayer(id)
    }, ANIMATION_DURATION)
  }, [ id, closeLayer ])

  useEffect(() => {
    const trapFocusElement = layerRef.current

    if (isVisible && layerRef.current) {
      const trapFocus = new TrapFocus(trapFocusElement, { withInitialFocus: false })

      trapFocusElement.focus()
      trapFocus.mount()

      let timeoutId

      if (scrollIntoView) {
        // avoid flashing because of drawer animation
        timeoutId = setTimeout(() => {
          layerRef.current?.scrollIntoView({ behavior: 'smooth' })
        }, ANIMATION_DURATION)
      }

      return () => {
        clearTimeout(timeoutId)

        trapFocus.unmount()
      }
    }
  }, [ isVisible ])

  if (!isVisible) {
    return null
  }

  const isActionButton = backButtonStyle === 'action-button'
  const iconName: IconName = isActionButton ? 'other/long-arrow-left' : '32/arrow-left'

  const contentClassName = cx('size-full pb-16 pt-80', isMobile && pxMobile === 16 ? 'px-16' : 'px-40')

  const backButtonOffsetClasses = pxMobile === 16 ? 'm-16' : 'mt-32 ml-40'

  const backButtonClassName = cx(
    'absolute left-0 top-0 cursor-pointer',
    isMobile ? backButtonOffsetClasses : 'm-32',
    isActionButton && 'py-8 text-gold-50'
  )

  return (
    <div ref={layerRef} className={cx(s.layer, 'absolute left-0 top-0 size-full', PlainModalBgColorToClassName[bgColor])}>
      <div className={contentClassName}>
        <button
          className={backButtonClassName}
          type="button"
          aria-label={backButtonLabel}
          onClick={handleCloseLayer}
        >
          <span className="flex items-center">
            <Icon name={iconName} />
            {
              isActionButton && (
                <span className="ml-12 text-t5">
                  {backButtonLabel}
                </span>
              )
            }
          </span>
        </button>
        {children}
      </div>
    </div>
  )
}


export default Layer
