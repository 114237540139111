import { createContext } from 'react'


type Context = {
  activeLayers: Map<string, any>
  openLayer: (id: string, props?: any) => void
  closeLayer: (id: string) => void
}

export const ModalWithLayersContext = createContext<Context>(null)
