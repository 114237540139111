import React, { type ReactNode } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'


type ModalHeroProps = {
  children: ReactNode
  className?: string
  withMargin?: boolean
}

const ModalHero: React.FC<ModalHeroProps> = ({ children, className, withMargin = true }) => {
  const { isMobile } = useDevice()

  return (
    <div className={cx(isMobile ? '-mx-16 -mt-40' : '-mx-40 -mt-48', withMargin && 'mb-32', className)}>
      {children}
    </div>
  )
}


export default React.memo(ModalHero)
