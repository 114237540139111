import { getElementPosition } from 'helpers/getters'
import { devicePx } from 'helpers'


type CreateTooltipProps = {
  trigger: HTMLElement
  tooltip: HTMLElement
  styles: Record<string, any>
  isMobile: boolean
  onShow?: () => void
  onHide?: () => void
  visible?: boolean
  side?: 'bottom-left'
  ignoreBlur?: boolean
  ignoreScroll?: boolean
  ignoreClick?: boolean
  ignoreTouchStart?: boolean
}

type CreateTooltip = (props: CreateTooltipProps) => () => void

const setMobilePosition = ({ trigger, tooltip, side }) => {
  const horizontalMobilePadding = devicePx(40)
  const verticalMobilePadding = devicePx(16)
  const viewportWidth = document.documentElement.clientWidth
  const triggerPosition = getElementPosition(trigger)
  const triggerBounds = trigger.getBoundingClientRect()
  let tooltipBounds = tooltip.getBoundingClientRect()

  let top
  let left
  let right

  // if distance between viewport's right side and trigger is less than tooltip width
  // then the tooltip should be placed to the left of the trigger
  if (triggerBounds.right + tooltipBounds.width > viewportWidth || side?.includes('left')) {
    left = triggerPosition.left + triggerBounds.width - tooltipBounds.width

    // if tooltip is off-screened
    if (left < 0) {
      if (triggerPosition.left < horizontalMobilePadding) {
        left = triggerPosition.left
      }
      else {
        left = horizontalMobilePadding
      }
    }

    // if tooltip doesn't fit the space between left and right sides of the viewport
    // by design the left and right padding size for full width tooltips is horizontalMobilePaddingpx
    if (tooltipBounds.width + left > viewportWidth - horizontalMobilePadding) {
      if (viewportWidth - triggerPosition.left - triggerBounds.width < horizontalMobilePadding) {
        right = viewportWidth - triggerPosition.left - triggerBounds.width
      }
      else {
        right = horizontalMobilePadding
      }
    }
  }
  else {
    left = triggerPosition.left
  }

  tooltip.style.left = `${left}px`
  tooltip.style.right = right ? `${right}px` : 'auto'

  tooltipBounds = tooltip.getBoundingClientRect()

  // ATTN top should be calculated after "left" and "right" because height of the tooltip can change (text wrapping)
  // if distance between viewport's top side and trigger is less than tooltip height
  // then the tooltip should be placed to the bottom of the trigger
  if (triggerBounds.top - tooltipBounds.height < verticalMobilePadding || side?.includes('bottom')) {
    top = triggerPosition.top + triggerBounds.height
  }
  else {
    top = triggerPosition.top - tooltipBounds.height
  }

  tooltip.style.top = `${top}px`
}

const setDesktopPosition = ({ trigger, tooltip, side }) => {
  const verticalPadding = 16
  const viewportWidth = document.documentElement.clientWidth
  const viewportHeight = document.documentElement.clientHeight
  const triggerPosition = getElementPosition(trigger)
  const triggerBounds = trigger.getBoundingClientRect()
  const tooltipBounds = tooltip.getBoundingClientRect()

  let top
  let left

  // if distance between viewport's bottom side and trigger is less than tooltip height
  // then the tooltip should be placed to the top of the trigger
  if (viewportHeight - triggerBounds.bottom - tooltipBounds.height < verticalPadding && !side?.includes('bottom')) {
    top = triggerPosition.top - tooltipBounds.height
  }
  else {
    top = triggerPosition.top + triggerBounds.height
  }

  // if distance between viewport's right side and trigger is less than tooltip width
  // then the tooltip should be placed to the left of the trigger
  if (triggerBounds.right + tooltipBounds.width > viewportWidth || side?.includes('left')) {
    left = triggerPosition.left + triggerBounds.width - tooltipBounds.width

    // if tooltip is off-screened
    if (left < 0) {
      left = verticalPadding
    }
  }
  else {
    left = triggerPosition.left
  }

  tooltip.style.top = `${top}px`
  tooltip.style.left = `${left}px`
}

const setTooltipPosition = ({ trigger, tooltip, isMobile, side }) => {
  if (isMobile) {
    setMobilePosition({ trigger, tooltip, side })
  }
  else {
    setDesktopPosition({ trigger, tooltip, side })
  }
}

const createTooltip: CreateTooltip = ({
  trigger,
  tooltip,
  styles,
  isMobile,
  onShow,
  onHide,
  visible,
  side,
  ignoreBlur,
  ignoreScroll,
  ignoreClick = true,
  ignoreTouchStart,
}) => {
  const show = () => {
    // remove style attribute from previous execution
    tooltip.removeAttribute('style')

    // show tooltip to allow the script to calculate tooltip width / height
    tooltip.classList.add(styles.visible)

    setTooltipPosition({ tooltip, trigger, isMobile, side })

    if (!ignoreTouchStart) {
      document.addEventListener('touchstart', hide, { passive: true, capture: true })
    }

    document.addEventListener('keydown', hideOnEscapePress)

    if (!ignoreClick) {
      document.addEventListener('click', hide)
    }

    if (!ignoreBlur) {
      window.addEventListener('blur', hide)
    }

    if (!ignoreScroll) {
      window.addEventListener('scroll', hide, { passive: true })
    }

    onShow?.()
  }

  const hide = () => {
    tooltip.classList.remove(styles.visible)

    document.removeEventListener('touchstart', hide, { capture: true })
    document.removeEventListener('keydown', hideOnEscapePress)
    document.removeEventListener('click', hide)
    window.removeEventListener('blur', hide)
    window.removeEventListener('scroll', hide)

    onHide?.()
  }

  const hideOnEscapePress = (event) => {
    if (event.key === 'Escape') {
      hide()
    }
  }

  trigger.addEventListener('focus', show)
  trigger.addEventListener('touchstart', show, { passive: true })
  trigger.addEventListener('mouseenter', show)

  if (!ignoreBlur) {
    trigger.addEventListener('blur', hide)
    trigger.addEventListener('mouseleave', hide)
  }

  if (visible) {
    show()
  }

  // unmount callback for useEffect
  return () => {
    trigger.removeEventListener('focus', show)
    trigger.removeEventListener('touchstart', show)
    trigger.removeEventListener('mouseenter', show)
    trigger.removeEventListener('mouseleave', hide)
    trigger.removeEventListener('blur', hide)

    document.removeEventListener('touchstart', hide, { capture: true })
    document.removeEventListener('keydown', hideOnEscapePress)
    document.removeEventListener('click', hide)
    window.removeEventListener('blur', hide)
    window.removeEventListener('scroll', hide)
  }
}


export default createTooltip
