import React, { useContext } from 'react'

import { ModalWithLayersContext } from '../../util'


type OpenButtonProps = {
  layerId: string
  children: React.ReactElement<{ onClick: (event?: MouseEvent) => void }>
}

const OpenButton: React.FC<OpenButtonProps> = ({ children, layerId }) => {
  const { openLayer } = useContext(ModalWithLayersContext)

  return (
    React.cloneElement(children, {
      onClick: (event) => {
        openLayer(layerId)

        if (typeof children.props.onClick === 'function') {
          children.props.onClick(event)
        }
      },
    })
  )
}


export default OpenButton
