import React from 'react'
import cx from 'classnames'

import { Icon, type IconName, Text, type TextStyle } from 'components/dataDisplay'


export const styles = [ 'error' ] as const

export type AlertStyle = typeof styles[number]

type AlertProps = {
  className?: string
  message: string | Intl.Message
  messageStyle?: TextStyle
  style: AlertStyle
  'data-testid'?: string
}

const ICONS: Record<AlertStyle, IconName> = {
  error: '24/info',
}

const STYLES: Record<AlertStyle, string> = {
  error: 'bg-light-red text-red',
}

const Alert: React.FC<AlertProps> = (props) => {
  const { 'data-testid': dataTestId, className, message, messageStyle = 'p4', style } = props

  return (
    <div className={cx(className, 'flex items-center rounded px-8 py-16', STYLES[style])}>
      <Icon className="min-w-24 min-h-24" name={ICONS[style]} />
      <Text
        className="ml-12"
        message={message}
        style={messageStyle}
        html
        data-testid={dataTestId}
      />
    </div>
  )
}

export default React.memo(Alert)
