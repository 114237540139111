import React from 'react'
import cx from 'classnames'
import { Icon, Text } from 'components/dataDisplay'
import messages from './messages'


type NoDataProps = {
  className?: string
  title?: string | Intl.Message
}

// TODO discuss with designers and add "No data" UI element - added on 1/18/21 by pavelivanov
const NoData: React.FunctionComponent<NoDataProps> = ({ className, title }) => (
  <div className={cx(className, 'text-center')}>
    <Icon name="other/no-items" />
    <Text className="mt-24" message={title || messages.title} style="p2" html />
  </div>
)


export default NoData
