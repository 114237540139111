import React from 'react'
import { useDevice } from 'device'
import cx from 'classnames'
import { Button } from 'components/inputs'
import type { ButtonProps } from 'components/inputs'


export type ModalButtonProps = Omit<ButtonProps, 'size'>

export type ModalButtonsProps = {
  className?: string
  primary?: ModalButtonProps
  secondary?: ModalButtonProps
  tertiary?: ModalButtonProps
  withMargin?: boolean
  customButton?: React.ReactNode
}

const ModalButtons: React.CFC<ModalButtonsProps> = (props) => {
  const { children, className, primary, secondary, tertiary, customButton, withMargin = true } = props
  const { isMobile } = useDevice()

  const marginClassName = isMobile ? 'mt-32' : 'mt-40'
  const rootClassName = cx(
    className,
    'grid gap-16',
    isMobile ? 'justify-items-stretch' : 'justify-items-center',
    {
      [marginClassName]: withMargin,
    }
  )

  return (
    <div
      className={rootClassName}
    >
      {children}
      {
        primary && (
          <Button
            {...primary}
            style={primary.style || 'primary'}
            size={56}
            width={280}
            data-testid="modalPrimaryButton"
          />
        )
      }
      {
        secondary && (
          <Button
            {...secondary}
            style={secondary.style || 'secondary'}
            size={56}
            width={280}
            data-testid="modalSecondaryButton"
          />
        )
      }
      {
        tertiary && (
          <Button
            {...tertiary}
            style={tertiary.style || 'tertiary'}
            size={56}
            data-testid="modalTertiaryButton"
          />
        )
      }
      {customButton}
    </div>
  )
}


export default ModalButtons
