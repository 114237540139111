import React from 'react'
import cx from 'classnames'

import { Text } from 'components/dataDisplay'


type NoteProps = {
  className?: string
  message: string | Intl.Message
  'data-testid'?: string
}

const Note: React.FunctionComponent<NoteProps> = ({ className, message, 'data-testid': dataTestId }) => (
  <Text
    className={cx(className, 'border-solid-gray-30 rounded bg-white px-16 py-20')}
    message={message}
    style="p3"
    html
    data-testid={dataTestId}
  />
)


export default Note
