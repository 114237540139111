import React from 'react'
import { useDevice } from 'device'
import { Text } from 'components/dataDisplay'

import messages from './messages'


export type ErrorBlockProps = {
  title?: Intl.Message | string
  text?: Intl.Message | string
}

const ErrorBlock: React.FunctionComponent<ErrorBlockProps> = (props) => {
  const { title = messages.title, text = messages.text } = props
  const { isMobile } = useDevice()

  return (
    <div className="max-w-464">
      <Text style={isMobile ? 'h4' : 'h2'} tag="h2" message={title} html />
      <Text className={isMobile ? 'mt-16' : 'mt-24'} style={isMobile ? 'p2' : 'p1'} tag="p" message={text} html />
    </div>
  )
}


export default ErrorBlock
