import React, { useContext } from 'react'
import { useDevice } from 'device'

import { ModalContext } from 'components/feedback'
import { Text, type TextStyle } from 'components/dataDisplay'


export type ModalHeadlineProps = {
  title: string | Intl.Message
  titleStyle?: TextStyle
  step?: string | Intl.Message
  text?: string | Intl.Message
  withMargin?: boolean
}

const ModalHeadline: React.FunctionComponent<ModalHeadlineProps> = (props) => {
  const { title, step, text, withMargin = true, titleStyle = 'sh3' } = props
  const { titleId, textId } = useContext(ModalContext)
  const { isMobile } = useDevice()

  return (
    <div className={withMargin ? 'mb-32' : undefined}>
      <Text
        className={isMobile ? 'pr-48' : 'pr-32'}
        id={titleId}
        message={title}
        style={titleStyle}
        html
        data-testid="title"
      />
      {
        Boolean(step) && (
          <Text
            className={isMobile ? 'mt-24' : 'mt-16'}
            message={step}
            style="eye2"
            color="gold-50"
            html
            data-testid="stepText"
          />
        )
      }
      {
        Boolean(text) && (
          <Text
            className={isMobile ? 'mt-24' : 'mt-16'}
            id={textId}
            message={text}
            style="p2"
            html
            data-testid="text"
          />
        )
      }
    </div>
  )
}


export default React.memo(ModalHeadline)
