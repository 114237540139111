import React from 'react'
import cx from 'classnames'

import { Text } from 'components/dataDisplay'


type ModalBasementNoteProps = {
  className?: string
  message: string | Intl.Message
}

const ModalBasementNote: React.FunctionComponent<ModalBasementNoteProps> = ({ className, message }) => {

  return (
    <div className={cx(className, 'text-center')}>
      <Text tag="span" style="p4" message={message} html />
    </div>
  )
}


export default React.memo(ModalBasementNote)
