import React from 'react'
import { useDevice } from 'device'

import { PlainModal, ModalHeadline, ModalButtons, ModalBasementNote } from 'components/feedback'
import type { PlainModalProps, ModalHeadlineProps, ModalButtonProps } from 'components/feedback'


export type ModalProps =
  PlainModalProps
  & Omit<ModalHeadlineProps, 'titleId' | 'textId'>
  & {
    basementNote?: string | Intl.Message
    buttonsClassName?: string
    primaryButton?: ModalButtonProps
    secondaryButton?: ModalButtonProps
    tertiaryButton?: ModalButtonProps
  }

const Modal: React.FC<ModalProps> = (props) => {
  const {
    children, className, overlayClassName,
    title, titleStyle, text, basementNote,
    buttonsClassName, primaryButton, secondaryButton, tertiaryButton,
    overlayClosable, type, bgColor, closeButtonIcon, withCloseButton,
    closeModal, withMargin,
    'data-testid': dataTestId,
  } = props

  const { isMobile } = useDevice()

  const areButtonsVisible = Boolean(primaryButton || secondaryButton || tertiaryButton)
  const isBasementNoteVisible = Boolean(basementNote)
  const isBasementVisible = areButtonsVisible || isBasementNoteVisible

  return (
    <PlainModal
      className={className}
      overlayClassName={overlayClassName}
      type={type}
      overlayClosable={overlayClosable}
      bgColor={bgColor}
      closeButtonIcon={closeButtonIcon}
      withCloseButton={withCloseButton}
      closeModal={closeModal}
      data-testid={dataTestId}
    >
      <ModalHeadline title={title} titleStyle={titleStyle} text={text} withMargin={withMargin} />
      <div className="flex flex-1 flex-col items-stretch justify-start">{children}</div>
      {
        isBasementVisible && (
          <div className={isMobile ? 'mt-32' : 'mt-40'}>
            {
              isMobile && isBasementNoteVisible && (
                <ModalBasementNote className="mb-16" message={basementNote} />
              )
            }
            {
              areButtonsVisible && (
                <ModalButtons
                  className={buttonsClassName}
                  primary={primaryButton}
                  secondary={secondaryButton}
                  tertiary={tertiaryButton}
                  withMargin={false}
                />
              )
            }
            {
              !isMobile && isBasementNoteVisible && (
                <ModalBasementNote className="mt-24" message={basementNote} />
              )
            }
          </div>
        )
      }
    </PlainModal>
  )
}


export default Modal
