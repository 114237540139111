import React, { useCallback, useContext, useState } from 'react'
import { PlainModal, type PlainModalProps } from 'components/feedback'

import Layer from './components/Layer/Layer'
import OpenButton from './components/OpenButton/OpenButton'
import { ModalWithLayersContext } from './util'


export type ModalWithLayersProps = PlainModalProps

type ModalWithLayersComponent = React.CFC<ModalWithLayersProps> & {
  Layer: typeof Layer
  OpenButton: typeof OpenButton
}

const ModalWithLayers: ModalWithLayersComponent = (props) => {
  const [ activeLayers, setActiveLayers ] = useState(new Map<string, any>())

  const openLayer = useCallback((id: string, props?: any) => {
    setActiveLayers((layers) => {
      const result = new Map(layers)

      result.set(id, props)

      return result
    })
  }, [])

  const closeLayer = useCallback((id: string) => {
    setActiveLayers((layers) => {
      const result = new Map(layers)

      result.delete(id)

      return result
    })
  }, [])

  return (
    <ModalWithLayersContext.Provider value={{ activeLayers, openLayer, closeLayer }}>
      <PlainModal {...props} />
    </ModalWithLayersContext.Provider>
  )
}

ModalWithLayers.Layer = Layer
ModalWithLayers.OpenButton = OpenButton

export const useModalWithLayers = () => useContext(ModalWithLayersContext)

export default ModalWithLayers
